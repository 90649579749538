<template>
  <div class="reviewResult">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">审核失败原因</div>
      <router-link tag="div" to="/uploadRules" class="rightBtn"
        >规则说明</router-link
      >
    </div>
    <div class="mian">
      <div class="reason">{{ info.reason }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    let dataStr = this.$route.query.data;
    let decodeData = window.atob(decodeURI(dataStr));
    this.info = JSON.parse(decodeURI(window.decodeURI(decodeData)));
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.reviewResult {
  height: 100%;
  color: #000;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #e6e6e6;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 31px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #333333;
    }

    .rightBtn {
      color: #333;
      font-size: 12px;
      // width: 17px;
      // height: 17px;
    }
  }
  .mian {
    padding: 0 12px;
    box-sizing: border-box;
    height: calc(100% - 45px);
    overflow-y: auto;
    font-size: 15px;
    .reason {
      margin-top: 18px;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #333333;
    }
  }
}
</style>
